body,
body .link {
  padding: 0;
  margin: 0;
  color: rgb(65, 65, 65);
  font-family: "Raleway", sans-serif;
}

.main {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

$iconTime: 3s;

/* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */
/* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */
/* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */ /* NAVBAR */

nav {
  margin: 0 auto;
  position: relative;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  height: 130px;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  z-index: 3;
  border-width: 10%;
  background-color: white;
}

.navLinks {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  margin: 0;
  margin-left: 10%;
  padding: 0;
  height: 40px;
  background-color: #f8f8f8;
  border-radius: 10px;
  // box-shadow: rgba(0, 0, 0, 0.801) 3px 3px 5px 0px;
  ul,
  li {
    display: inline;
    list-style: none;
    margin: auto;
  }
}

.navbarIn {
  animation: navSlideIn 1s ease forwards;
  z-index: 1;
}

.navbarOut {
  animation: navSlideOut 1s ease forwards;
}

.facePic {
  width: 60px;
  height: 84px;
  padding: 10px;
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  position: relative;
  z-index: 4;
  &:hover {
    transform: scale(1.1) !important;
  }
  &.animate {
    animation: popOut 1s ease forwards;
    -webkit-animation: popOut 1s ease forwards;
  }
}

.faceDiv {
  position: relative;
  z-index: 4;
  perspective: 1000px;
  -webkit-perspective: 1000px;
}

@media screen and (max-width: 605px) {
  .navbarIn {
    color: rgb(32, 32, 32);
    font-size: 15px;
    height: 90px;
    margin-bottom: 30px;
  }

  .navbarOut {
    color: rgb(32, 32, 32);
    font-size: 15px;
    height: 90px;
    margin-top: -28px;
    margin-bottom: 30px;
  }

  .facePic {
    width: 50px;
    padding: 2%;
  }
}

.link {
  position: relative;
  display: inline-block;
  justify-content: space-around;
  color: rgb(32, 32, 32);
  text-decoration: none;
  font-weight: 300;
}

.link::after {
  content: "";
  position: absolute;
  justify-content: space-around;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  border-bottom: 2px solid rgb(56, 56, 56);
}

.link,
.link::after {
  transition: all 0.15s linear;
}

.link:hover {
  color: rgb(0, 0, 0);
  transform: scale(1.1);
}

.link:hover::after {
  left: 0;
  width: 100%;
}

/* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */
/* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */
/* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */ /* HOME */

.home h1,
h2 {
  text-align: center;
  margin: 15px;
}

.homeDesc p {
  font-family: "Courgette", cursive;

  font-size: 20px;
  // font-weight: bold;
  // line-height: 15px;
}

.skillsLogoWrapper {
  margin-top: 1%;
  margin: 0 auto;
  width: 95%;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: fit-content;
  flex-direction: row;
  justify-content: space-around;
  /* border: 2px solid #1c6ea4; */
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;

  img {
    max-height: 65px;
    height: 11vh;
    padding: 5px 25px;
  }

  p {
    &:hover {
      animation: jiggle 0.8s linear infinite;
    }
    &.implode {
      animation: implode 1.4s ease-out forwards;
      svg path {
        animation: destruct 1.2s linear forwards;
      }
    }
  }

  svg {
    height: 11vh;
    max-height: 65px;
    padding: 5px 25px;
    max-width: 11vh;
    stroke-width: 5px;
  }
}

.skillsActive {
  animation: pulse 0.5s cubic-bezier(0.27, 0.76, 0.59, 1.43) alternate infinite;
  // animation: swing 1s linear forwards infinite 1s;
}

.lasers {
  position: absolute;
  left: 0;
  top: 0;
  height: 110vh;
  width: 100vw;
  z-index: 5;
  pointer-events: none;
  stroke-dasharray: 100;
  stroke-dashoffset: 1000;
  animation: dash 0.3s linear;
  filter: drop-shadow(0px 0px 5px rgb(218, 0, 0));

  @media screen and (max-width: 605px) {
    height: 200vh;
  }
}

.laser {
}

.htmlLogo path {
  stroke: #f0470d;
  // stroke-width: 5px;
  opacity: 0;
  stroke-dasharray: 3188px;
  stroke-dashoffset: 3188px;
  animation: display3000 $iconTime linear forwards;
  fill: #f0470d;
}

.cssLogo path {
  stroke: #2196f3;

  stroke-dasharray: 3188px;
  stroke-dashoffset: 3188px;
  animation: display3000 $iconTime linear forwards 0.2s;
}

.scssLogo path {
  opacity: 0;
  stroke: #cf649a;
  stroke-dasharray: 3188px;
  stroke-dashoffset: 3188px;
  animation: display3000 $iconTime linear forwards 0.4s;
  fill: #cf649a;
}

.rubyLogo path {
  opacity: 0;
  stroke: #b10d23;
  stroke-dasharray: 3000px;
  stroke-dashoffset: 3000px;
  animation: display3000 $iconTime linear forwards 0.6s;
  fill: #b10d23;
}

.sinatraLogo path {
  opacity: 0;
  stroke: #e76565;
  stroke-dasharray: 2000px;
  stroke-dashoffset: 2000px;
  animation: display2000 $iconTime linear forwards 0.8s;
}

.railsLogo path {
  opacity: 0;
  stroke: #e76565;
  stroke-dasharray: 3188px;
  stroke-dashoffset: 3188px;
  animation: display3000 $iconTime linear forwards 1s;
}

.skillsLogo.actionLogo {
  animation: blank 1.2s linear forwards, fadeIn $iconTime linear forwards 1.2s;
}

.javascriptLogo path {
  stroke: #e0c85b;
  stroke-width: 20px;
  opacity: 0;
  stroke-dasharray: 1000px;
  stroke-dashoffset: 1000px;
  animation: display1000 $iconTime linear forwards 1.4s;
}

.reactLogo path {
  stroke: #53c1de;
  opacity: 0;
  stroke-dasharray: 3188px;
  stroke-dashoffset: 3188px;
  animation: display3000 $iconTime linear forwards 1.6s;
}

.sqlLogo path {
  stroke: #feb205;
  stroke-width: 1px;

  opacity: 0;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: display1000 $iconTime linear forwards 1.8s;
  fill: #feb205;
}

.googleLogo path,
circle {
  stroke: #494949;
  opacity: 0;
  animation: display2000 $iconTime linear forwards 2s;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
}

.herokuLogo path {
  opacity: 0;
  stroke: #805cbd;
  animation: display2000 $iconTime linear forwards 2.2s;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  fill: #7e57c2;
}

.skillsLogo.netlify {
  opacity: 0;
  animation: fadeIn $iconTime linear forwards 2.4s;
}

.comptiaLogo {
  .st0 {
    fill: #fff;
  }
  .st1 {
    fill: #e2231a;
  }
  .st2 {
    fill: #6a747c;
  }

  path {
    opacity: 0;
    stroke: #000000;
    stroke-width: 1px;
    animation: display2000 $iconTime linear forwards 2.6s;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
  }
}

.zendeskLogo path {
  opacity: 0;
  stroke: #03363d;
  stroke-width: 1px;
  animation: display2000 $iconTime linear forwards 2.8s;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
}

.photoshopLogo path {
  opacity: 0;
  stroke: #393687;
  stroke-width: 1px;
  animation: display2000 $iconTime linear forwards 3s;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
}

.aseLogo path:nth-child(0),
.aseLogo path:nth-child(2),
.aseLogo path:nth-child(4),
.aseLogo path:nth-child(7),
.aseLogo path:nth-child(9),
.aseLogo path:nth-child(10),
.aseLogo path:nth-child(11),
.aseLogo path:nth-child(12),
.aseLogo path:nth-child(13),
.aseLogo path:nth-child(14),
.aseLogo path:nth-child(15),
.aseLogo path:nth-child(16),
.aseLogo path:nth-child(17),
.aseLogo path:nth-child(19),
.aseLogo path:nth-child(21),
.aseLogo path:nth-child(18) {
  opacity: 0;
  stroke: #5a5a5a;
  stroke-width: 1px;
  animation: display2000 $iconTime linear forwards 3.2s;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
}

.aseLogo path:nth-child(4) {
  fill: #1879bf;
}

.skillsLogoMore {
  opacity: 0;
  animation: fadeJiggleIn $iconTime cubic-bezier(0.27, 0.76, 0.59, 1.43)
    forwards 3.4s;
}

/* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */
/* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */
/* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */ /* SLIDE THEME */

.content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.about,
.contact,
.projects {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-left: 5vw;
  margin-right: 5vw;
  animation: slideIn 1s ease forwards;
  height: 50vh;
}

.about.animation-enter,
.contact.animation-enter,
.projects.animation-enter {
  animation: slideIn 1s ease forwards;
}

.animation-enter-done {
}

.about.animation-exit,
.contact.animation-exit,
.projects.animation-exit,
.resume.animation-exit {
  animation: slideOut 1s ease forwards;
}

.home {
  margin-top: 50px;
  margin-left: 5vw;
  margin-right: 5vw;
  z-index: 2;
  text-align: center;
  height: 100vh;
  animation: bottomIn 1s ease forwards;
}

.home.animation-enter-active {
  animation: bottomIn 1s ease forwards;
}

.home.animation-exit {
  animation: slideOut 1s ease forwards;
}

.resume.animation-enter {
  animation: none;
}

.resume.animation-exit {
  /* animation: none; */
}

/* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */
/* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */
/* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */ /* ABOUT */

.about {
  margin-top: -20px;
  z-index: 1;
  h2 {
    text-align: center;
    // filter: drop-shadow(8px 8px 5px rgba(0, 0, 0, 0.5));
  }
  p {
    text-align: left;
  }
  img {
    // border-radius: 3px;
    // -webkit-box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.5);

    // box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.5);
    filter: drop-shadow(8px 8px 5px rgba(0, 0, 0, 0.5));
  }
  h3 {
    font-family: "Courgette", cursive;
    font-size: 20px;
    font-weight: 100;
  }
}

.aboutPicWrapper {
  position: relative;
  padding-top: 10px;
  padding-right: 25px;
  padding-bottom: 20px;
  float: left;
  @media screen and (max-width: 580px) {
    max-width: 50%;
  }
  img {
    float: left;
    max-width: 300px;
    @media screen and (max-width: 580px) {
      max-width: 100%;
    }
  }
}

.hobbiesPicsWrapper {
  display: flex;
  align-items: center;
  height: fit-content;
  flex-direction: row;
  justify-content: space-around;
  font-size: 9px;
  line-height: 11px;
  font-weight: bold;
  padding: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  img {
    max-height: 100px;
    height: 50%;
    margin: 10px;
  }
}

/* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */
/* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */
/* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */ /* PROJECTS */

.projects {
  margin-top: -20px;
  h2 {
    margin-bottom: 20px;
  }
}

.projectDivs {
  margin: 0 auto;
  background: url("./images/matrix.jpg") no-repeat center top;
  width: 100%;
  background-size: 100% 70%;
  /* border-top: 2px solid rgba(182, 182, 182, 0.3); */
  // width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.descText {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: -5px;
  text-align: center;
  z-index: 0;
  /* padding-top: 0px; */
}

.projectLogoWrapper {
  width: 99%;
  display: flex;
  align-items: center;
  height: fit-content;
  flex-direction: row;
  justify-content: space-around;
  /* border: 2px solid #1c6ea4; */
  text-align: center;
  font-size: 9px;
  line-height: 11px;
  font-weight: bold;
}

.projectLogo {
  padding: 10px 2px 0px 2px;

  max-height: 34px;
}

.mirrorDiv,
.gameDiv,
.recipeDiv {
  /* border: 2px solid #1c6ea4; */

  margin-top: 5%;
  margin-left: 1%;
  // margin-right: 0.1%;
  margin-bottom: 10%;
  height: 301px;
  width: 396px;
  z-index: 1;

  position: relative;
  overflow: hidden;
}

.mirrorPic,
.gamePic,
.recipePic {
  width: 380px;
  height: 285px;
  position: absolute;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(120, 120, 120, 0.77);
  box-shadow: 5px 5px 10px 0px rgba(120, 120, 120, 0.77);
  background-color: rgba(0, 0, 0, 0.89);
  transition: transform 0.2s, width 0.2s, height 0.2s;
  z-index: 1;
}

.mirrorDesc,
.gameDesc,
.recipeDesc {
  opacity: 0;
  z-index: 2;
  position: absolute;
  // height: 286px;
  // width: 381px;
  height: 301px;
  width: 396px;
  background: rgba(255, 255, 255, 0.85);
  transition: transform 0.2s;
  font-weight: bold;

  /* line-height: 20px;
  display: inline-block;
  vertical-align: middle; */
}

.mirrorDesc:hover,
.gameDesc:hover,
.recipeDesc:hover {
  opacity: 1;
  animation: fadeIn 0.2s linear forwards;
  transform: scale(1.04);
}

.mirrorDiv:hover .mirrorPic,
.gameDiv:hover .gamePic,
.recipeDiv:hover .recipePic {
  // transform: scale(1.07);

  width: 396px;
}

// .border {
//   width: 90%;
//   margin: 0 auto;
//   border-top: 2px solid rgba(182, 182, 182, 0.3);
//   position: relative;
// }

.borderB {
  width: 90%;
  margin: 0 auto;
  /*
  border-top: 2px solid rgba(182, 182, 182, 0.3); */
  position: relative;
  /* margin-top: -60px; */
}

.monitors {
  /* position: relative; */
}

.monitor {
  width: 410px;
  position: absolute;
  margin-left: -10px;
  bottom: -60px;
  height: 480px;
  z-index: 1;
}

.toggleSwitch {
  width: 180px;
  margin: auto;
  font-size: 0.9em;
  position: relative;
  height: 1.9em;
  line-height: 1.9em;
  border-radius: 0.3em;
  background: #ccc;
}

.switch1,
.switch2,
.switch3 {
  cursor: pointer;
  position: relative;
  display: block;
  float: left;
  -webkit-transition: 300ms ease-out;
  -moz-transition: 300ms ease-out;
  transition: 300ms ease-out;
  padding: 0 1em;
}
.selector {
  position: absolute;
  text-align: center;
  top: -2px;
  width: 60px;
  height: 33px;
  padding: 2px;
  box-sizing: border-box;
  -webkit-transition: 300ms ease-out;
  -moz-transition: 300ms ease-out;
  transition: 300ms ease-out;
  border-radius: 0.3em;
  color: white;
  background-color: #40b1fc;
  -moz-box-shadow: 0px 2px 13px 0px #9b9b9b;
  -webkit-box-shadow: 0px 2px 13px 0px #9b9b9b;
  -o-box-shadow: 0px 2px 13px 0px #9b9b9b;
  box-shadow: 0px 2px 13px 0px #9b9b9b;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#9b9b9b, Direction=180, Strength=13);
}

.controls {
  position: relative;
  width: 318px;
  height: 45px;
  background: #a1a1a1;
  // background: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  bottom: 10%;
  border-radius: 10px;
  top: -15%;
}

.material-icons.leftArrow {
  font-size: 70px;
  margin: 0;
  color: #ccc;
  transition: transform 0.2s;
}

.material-icons.rightArrow {
  font-size: 70px;
  color: #ccc;
  transition: transform 0.2s;
  margin: 0;
}

.material-icons.leftArrow:hover,
.material-icons.rightArrow:hover {
  transform: scale(1.1);
}

.material-icons.leftArrow:active,
.material-icons.rightArrow:active {
  color: #40b1fc;
  transform: scale(0.9);
}

.flip-enter-active {
  animation: flipIn 1s cubic-bezier(0.27, 0.76, 0.59, 1.43) forwards;
}

.flip-exit {
  animation: flipOut 1s ease forwards;
}

.slide-enter-active {
  animation: slideIn2 1s ease forwards;
}

.slide-exit {
  animation: slideOut2 1s ease forwards;
}

.fade-enter-active {
  animation: fade50In 1s ease forwards;
}

.fade-exit {
  animation: fade50Out 1s ease forwards 0.2s;
}

.slideLeft-enter-active {
  animation: slideLeftIn 1s ease forwards;
}

.slideLeft-exit {
  animation: slideLeftOut 1s ease forwards;
}

@media screen and (max-width: 915px) {
  .projectDivs {
    background-size: 100% 90%;
  }
}

@media screen and (max-width: 450px) {
  .projects h2 {
    margin-bottom: 15px;
  }

  .descText {
    padding-left: 4px;
    padding-right: 4px;
    margin-top: -10px;
  }

  .projectLogo {
    padding: 18px 2px 0px 2px;

    max-height: 34px;
  }

  .mirrorDiv,
  .gameDiv,
  .recipeDiv {
    margin-top: 8%;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 1%;

    height: 239px;
    width: 320px;
  }

  .mirrorPic,
  .gamePic,
  .recipePic {
    width: 320px;
    position: absolute;
    -webkit-box-shadow: 5px 5px 15px 0px rgba(120, 120, 120, 0.77);
    box-shadow: 5px 5px 15px 0px rgba(120, 120, 120, 0.77);
    transition: transform 0.2s;

    z-index: 1;
  }

  .mirrorDesc,
  .gameDesc,
  .recipeDesc {
    font-size: 14px;

    position: absolute;
    height: 239px;
    width: 320px;
  }

  .projectLogo {
    padding: 8px 2px 0px 2px;

    max-height: 30px;
  }

  .mirrorDesc h2,
  .gameDesc h2,
  .recipeDesc h2 {
    margin-bottom: 8px;
  }

  .borderB {
    margin-top: 20px;
  }

  .projectBack {
    width: 104%;
    height: 115vh;
    left: -2%;
  }

  .controls {
    top: 20%;
  }
}

/* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */
/* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */
/* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */ /* RESUME */

.resume {
  position: relative;
  text-align: center;
  z-index: 1;
}

.pdf {
  position: relative;
  width: auto;
  text-align: center;
  display: inline-block;
  margin-top: -20px;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 605px) {
    margin-top: 0px;
  }
}

.pdfPage2 {
  margin-top: -50px;
  z-index: 2;
  @media screen and (max-width: 850px) {
    margin-top: -20px;
  }
}

/* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */
/* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */
/* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */ /* CONTACT */

.contact {
  text-align: center;
  font-size: 23px;
  text-decoration: none;
  // background-image: url("./images/phone.jpg");
  // background-position: -200px 80px;
  // background-size: 130% 100%;
  // height: 100vh;
  // background: url("./images/matrix.jpg") no-repeat center top;
  h1 {
    // color: #4b4a4a;
    display: inline;
    opacity: 0;
    text-shadow: 5px 5px 5px rgba(41, 41, 41, 0.5);
  }
  h1:nth-child(1) {
    animation: fadeIn 2s linear forwards 0.5s;
  }
  h1:nth-child(2) {
    animation: fadeIn 2s linear forwards 1s;
  }
  h1:nth-child(3) {
    animation: fadeIn 2s linear forwards 1.5s;
  }
  h1:nth-child(4) {
    animation: fadeIn 2s linear forwards 2s;
  }
  h1:nth-child(5) {
    animation: fadeIn 2s linear forwards 2.5s;
  }

  svg {
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
    text-decoration: none;
    padding: 15px;
    width: 75px;
    height: 75px;
    transition: transform 0.3s;
    fill: rgba(65, 183, 252, 0.308);
    animation: draw 5s cubic-bezier(0.27, 0.76, 0.59, 2.43) infinite alternate
      2.5s;
    stroke-dashoffset: 3200;
    stroke: #0077ff;
    stroke-width: 10px;

    filter: drop-shadow(0px 0px 3px rgb(0, 183, 255));
  }

  svg:hover {
    animation: draw2 3s ease infinite alternate;

    stroke-dashoffset: 3200;
    stroke-dasharray: 200;
    stroke-width: 15px;
    // stroke: black;
  }

  a {
    text-decoration: none;
  }
  form {
    top: 10px;
    position: relative;
    margin: 0 auto;
    width: 500px;
    height: 330px;
    background-color: rgb(235, 235, 235);
    padding: 25px;
    border-radius: 10px;

    // box-shadow: rgba(0, 0, 0, 0.801) 3px 3px 10px 0px;
  }
  input {
    width: 494px;
    height: 30px;
  }
  textarea {
    width: 494px;
    height: 80px;
  }
  p {
    text-align: left;
  }
  button {
    color: rgb(255, 255, 255);
    width: 90px;
    font-size: 12px;
    line-height: 10px;
    padding: 10px;
    border-radius: 10px;
    font-family: Georgia, serif;
    font-weight: normal;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    background: #2284c5;
    box-shadow: rgba(0, 0, 0, 0.801) 3px 3px 10px 0px;
    border: 2px solid rgb(28, 110, 164);
    display: inline-block;
    transition: transform 0.3s;
    margin-bottom: 20px;
    &:hover {
      background: #40b1fc;
      transform: scale(1.05);
    }
    &:active {
      background: #144e75;
      transform: scale(1);
    }
  }
}

@keyframes draw {
  0% {
    stroke-dasharray: 3200 2000;
    fill: transparent;
    stroke-dashoffset: 3200;
  }
  100% {
    stroke-dasharray: 3200 2000;
    stroke-dashoffset: 0;
    fill: rgba(65, 183, 252, 0.308);
  }
}

@keyframes draw2 {
  0% {
    stroke-dashoffset: 0;
    fill: rgba(65, 183, 252, 0.308);
    transform: scale(1);
  }
  50% {
    stroke-dashoffset: 1500;
    fill: transparent;
    transform: scale(1.13);
  }
  100% {
    stroke-dashoffset: 3300;
    fill: rgba(65, 183, 252, 0.308);
    transform: scale(1);
  }
}

.response {
  width: 100%;
  font-size: 20px;
  position: absolute;
  text-align: center;
  top: 10px;
}

@media screen and (max-width: 705px) {
  .contact {
    padding: 1%;
    text-align: center;
    font-size: 4vw;
    img {
      text-decoration: none;
      padding: 5%;
      width: 14vw;
      height: 14vw;
      transition: transform 0.3s;
      /* -webkit-box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.77);
    box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.77); */
    }
  }

  .contact form {
    width: 80%;
    height: fit-content;
  }

  .contact input {
    width: 98%;
    height: 30px;
  }

  .contact textarea {
    width: 98%;
    height: 80px;
  }

  .contact button {
    width: 60px;
    font-size: 10px;
  }
}

/* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */
/* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */
/* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */ /* FOOTER */

.footer {
  position: relative;
  bottom: 0px;

  text-align: left;
  width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 12px;
  border-top: 2px solid rgba(182, 182, 182, 0.3);
  margin-top: 150px;
  background: #ffffff;
}

/* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */
/* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */
/* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */ /* ANIMATION */

@keyframes bottomIn {
  0% {
    opacity: 0;
    transform: translateY(70%);
  }

  150% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes topIn {
  0% {
    opacity: 0;
    transform: translateY(-70%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes navSlideOut {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-72%);
  }
}

@keyframes navSlideIn {
  0% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);

    opacity: 0;
  }
  100% {
    transform: translateX(0%);

    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade50In {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade50Out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes flipOut {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}

@keyframes flipIn {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes slideIn2 {
  0% {
    transform: translateX(-100%);

    opacity: 1;
  }
  100% {
    transform: translateX(0%);

    opacity: 1;
  }
}

@keyframes slideOut2 {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  80% {
    opacity: 0.2;
  }

  95% {
    opacity: 0;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slideLeftIn {
  0% {
    transform: translateX(100%);

    opacity: 1;
  }
  100% {
    transform: translateX(0%);

    opacity: 1;
  }
}

@keyframes slideLeftOut {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  80% {
    opacity: 0.2;
  }

  95% {
    opacity: 0;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes popOut {
  0% {
    transform: scale(1.1) rotate(0deg);
  }
  10% {
    /* transform: scale(0.2); */
  }
  20% {
    /* transform: scale(0.3); */
  }
  30% {
  }
  40% {
  }
  50% {
    transform: scale(0.1) rotate(0deg);
  }
  60% {
  }
  70% {
  }
  80% {
  }
  90% {
  }
  100% {
    transform: scale(1.1) rotate(360deg);
  }
}

@keyframes blinker {
  0% {
  }
  50% {
    opacity: 0.92;
  }
  100% {
  }
}

@keyframes fadeJiggleIn {
  0% {
    opacity: 0;
  }

  85% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(-10deg);
  }
  95% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

@keyframes jiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes blank {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes implode {
  0% {
  }
  5% {
    transform: rotate(-5deg);
  }
  10% {
    transform: rotate(5deg);
  }
  15% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(5deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  30% {
    transform: rotate(5deg);
  }
  35% {
    transform: rotate(-5deg);
  }
  40% {
    transform: rotate(5deg);
  }
  45% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  55% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(5deg);
  }
  65% {
    transform: rotate(-5deg);
  }
  70% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(5deg);
  }
  85% {
    transform: rotate(-5deg);
  }
  90% {
    transform: scale(1) rotate(5deg);
  }

  100% {
    transform: scale(0) rotate(-5deg);
  }
}

@keyframes destruct {
  0% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  20% {
    // fill: transparent;
    // opacity: 1;
  }
  70% {
    fill: transparent;
    opacity: 1;
  }
  100% {
    fill: transparent;
    stroke-dashoffset: 3000;
    opacity: 1;
  }
}

@keyframes pulse {
  to {
    transform: scale(1.15);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@mixin animation-mixin($name, $offset) {
  @keyframes #{$name} {
    0% {
      stroke-dashoffset: $offset;
      fill: transparent;
      opacity: 1;
    }
    70% {
      fill: transparent;
      opacity: 1;
    }
    100% {
      opacity: 1;
      stroke-dashoffset: 0;
    }
  }
}

@include animation-mixin(display1000, 1000px);
@include animation-mixin(display2000, 2000px);
@include animation-mixin(display3000, 3000px);

$fireColor: rgb(255, 80, 0);
$fireColorT: rgba(255, 80, 0, 0);
$dur: 1s;
$blur: 0.02em;
$fireRad: 3em;
$parts: 50;
$partSize: 5em;
$fireSize: 8px;

// $fireRad: 3em;
// $parts: 50;
// $partSize: 5em;

.fire {
  font-size: $fireSize;
  filter: blur($blur);
  -webkit-filter: blur($blur);
  // margin: 3em auto 0 auto;
  position: absolute;
  width: 10em;
  height: 12em;
  // width: 10em;
  // height: 12em;
  animation: fadeOut 6s linear forwards;
  z-index: 2;
  pointer-events: none;

  @media screen and (max-width: 605px) {
    margin-top: 40px;
  }
}
.particle {
  animation: rise $dur ease-in 7s;
  background-image: radial-gradient($fireColor 20%, $fireColorT 70%);
  border-radius: 50%;
  mix-blend-mode: screen;
  opacity: 0;
  position: absolute;
  bottom: 0;
  width: $partSize;
  height: $partSize;
  // spread particles out in time and x-position to get desired effect
  @for $p from 1 through $parts {
    &:nth-of-type(#{$p}) {
      animation-delay: $dur * random();
      left: calc((100% - #{$partSize}) * #{($p - 1) / $parts});
    }
  }
}
@keyframes rise {
  from {
    opacity: 0;
    transform: translateY(0) scale(1);
  }
  25% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-10em) scale(0);
  }
}
